import React, { useEffect } from "react";
import { lazy, Suspense } from "react";
import { Routes, Route } from "react-router-dom";
import AuthWrapper from "api/authwarpper";
import Loader from 'component/loader';

import "./App.scss";
// import "antd/dist/antd.css"; // or 'antd/dist/antd.less'
import 'antd/dist/antd.min.css';
import './styles/riot-font.css';
// import Dashboard from "pages/dashboard";
import { useNavigate } from "react-router-dom";
import axios from "axios";


const Home = lazy(() => import("pages/home"));
const Login = lazy(() => import("pages/login"));

function App() {
  const navigate = useNavigate();

  useEffect(() => {
    // Simulate an API request and check for a 401 response
    const checkFor401Error = async () => {
      try {
        const response = await axios.post("https://api.jayasulochanachits.com/api/v1/agent/get",{},{
          headers: {
            'Authorization': 'Bearer ' +localStorage.getItem('token'),
            'Content-Type': 'application/json',
          },
        });
        console.log("Response Status:", response.status);

        if (response.status === 401 || response.status === 404)  {
          // Redirect to the login page
          navigate(Login);
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };

    checkFor401Error();
  }, [navigate]);
  return (
    <Suspense fallback={<Loader />}>
      <div className="App">
        <Routes>
          <Route key={'login'} path="/login" element={<Login />} />
          <Route key={'default'} path="*" element={<Home />} />
        </Routes>
      </div>
    </Suspense>
  );
}

export default App;
