import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios"; // Make sure to import Axios

function AuthWrapper({ children }) {
  const navigate = useNavigate();

  useEffect(() => {
    // Simulate an API request and check for a 401 or 404 response
    const checkFor401Error = async () => {
      try {
        const response = await axios.post(
          "https://api.jayasulochanachits.com/api/v1/agent/get",
          {},
          {
            headers: {
              'Authorization': 'Bearer ' + localStorage.getItem('token'),
              'Content-Type': 'application/json',
            },
          }
        );

        if (response.status === 401 || response.status === 404) {
          // Redirect to the login page
          navigate('/login');
        }
      } catch (error) {
        // Handle errors (e.g., network issues)
        console.error("Error:", error);
      }
    };

    checkFor401Error();
  }, [navigate]);

  return <>{children}</>;
}

export default AuthWrapper;
